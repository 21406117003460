import React from 'react'
import styled from '@emotion/styled'
import { BACKGROUND, BP_TABLET, CONTEXT, DEFAULT_WRAPPER } from '../../constants'
import Link from '@mui/material/Link'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { IMAGE_LIST } from './images'

const DefaultWrapper = styled.section`
	${DEFAULT_WRAPPER}
`
const ContactWrapper = styled.div`
	font-size: 20px;
	margin: 0 auto;
	max-width: 800px;
	padding-top: 100px;
	width: 90%;

	@media (min-width: ${BP_TABLET}) {
		margin: 50px auto;
		padding-top: 150px;
		padding-bottom: 60px;
	}
`
const BackgroundWrapper = styled.div`
	${BACKGROUND};
`
const ContactHeader = styled.h2``
const Context = styled.div`
	${CONTEXT}

	a {
		text-decoration: none;
	}
`

export default function Contact() {
	return (
		<DefaultWrapper>
			<BackgroundWrapper />
			<ContactWrapper>
				<ContactHeader>Contact 4 P’s Dog Training, LLC</ContactHeader>
				<Context>
					You can reach me at:
					<br />
					Dianne Lotti
					<br />
					Cell phone: <Link href="tel:(508) 472-9294">(508) 472-9294</Link>
					<br />
					Email: <Link href="mailto:4PsDogTraining@gmail.com">4PsDogTraining@gmail.com</Link>
					<br />
					Website: <Link href="www.4psdogtraining.com">www.4psdogtraining.com</Link>
				</Context>
				<ImageList variant="masonry" cols={4} gap={8}>
					{IMAGE_LIST.map(item => (
						<ImageListItem key={item.img}>
							<img
								alt={item.title}
								src={`http://4psdogtraining.com/static/media/${item.src}?w=248&fit=crop&auto=format`}
								srcSet={`${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
								height={'auto'}
								width={'auto'}
								style={{ minHeight: 10 }}
								loading="lazy"
							/>
						</ImageListItem>
					))}
				</ImageList>
			</ContactWrapper>
		</DefaultWrapper>
	)
}
